import { $cartItemsCount } from "@/store/cart-store";
import type { Shop, User } from "@/types";
import { useStore } from "@nanostores/react";
import { ShoppingCart } from "lucide-react";

interface Props {
  shop: Shop;
  user?: User | null;
}

const CartButton = ({ shop, user }: Props) => {
  const cartCount = useStore($cartItemsCount);

  return (
    <a href={`/shops/${shop.slug}/cart`}>
      <button
        className="relative p-2 hover:bg-kp-accent/10 rounded-full transition-colors"
        aria-label="Shopping Cart">
        <ShoppingCart className="w-6 h-6 text-kp-primary" />
        <span className="absolute -top-1 -right-1 bg-kp-accent text-kp-black w-5 h-5 rounded-full text-xs flex items-center justify-center">
          {cartCount}
        </span>
      </button>
    </a>
  );
};

export default CartButton;
